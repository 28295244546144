<template>
  <div class="open-session-page">
    <div class="open-session-page__title">DEMO GAME</div>
    <WeveLoading :loading="working">
      <div class="open-session-page__text">But first, show us you're real:</div>
      <div class="open-session-page__recaptcha" ref="recaptcha" />
    </WeveLoading>
  </div>
</template>

<script>
import axios from "axios"
import WeveLoading from "@/components/WeveLoading"
import BASE_URL from "@/services/base-url"

export default {
  name: "OpenSession",
  props: {
    id: {
      type: String,
      default: null
    }
  },
  components: {
    WeveLoading
  },
  data() {
    return {
      working: false
    }
  },
  async mounted() {
    const self = this
    window.reCaptchaCallback = function () {
      grecaptcha.render(self.$refs.recaptcha, {
        sitekey: process.env.VUE_APP_CAPTCHA_KEY,
        theme: "dark",
        callback: async response => {
          try {
            self.working = true
            const id = self.id || ""
            const { data } = await axios.post(
              `${BASE_URL}open-session?id=${id}&response=${encodeURIComponent(
                response
              )}`,
              {
                responseType: "json",
                headers: {
                  "content-type": "application/json"
                }
              }
            )

            const sessionID = data?.data?.id

            document.location.href = `/login/${sessionID}`
          } catch (e) {
            const msg = e.response?.data?.message || e.request || e.message
            await self.$info(msg)
          } finally {
            grecaptcha.reset(self.$refs.recaptcha)
            self.working = false
          }
        }
      })
    }

    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=reCaptchaCallback&render=explicit"
    )
    document.head.appendChild(script)
  }
}
</script>
<style lang="scss">
.open-session-page {
  margin-top: 100px;
  text-align: center;
  &__title {
    color: #f8f9fb;
    font-family: "Right Grotesk", san-serif;
    font-weight: 900;
    font-size: 60px;
    padding: 0 0 12px;
    margin: 0 0 75px;
    font-style: normal;
    text-transform: uppercase;
    line-height: 38px;
    &:hover {
      color: #fff;
    }
  }
  &__text {
    margin: 60px 0 30px;
    color: #f8f9fb;
    font-weight: bold;
    font-size: 18px;
  }
  &__recaptcha {
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }
}
</style>
