var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "open-session-page" },
    [
      _c("div", { staticClass: "open-session-page__title" }, [
        _vm._v("DEMO GAME"),
      ]),
      _c("WeveLoading", { attrs: { loading: _vm.working } }, [
        _c("div", { staticClass: "open-session-page__text" }, [
          _vm._v("But first, show us you're real:"),
        ]),
        _c("div", {
          ref: "recaptcha",
          staticClass: "open-session-page__recaptcha",
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }